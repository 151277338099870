import React from "react"
import Layout from "../components/Layout"
const NewsLetter = () => {
  return (
    <Layout>
      <section className="newsletter-page">
        <div className="page-center">
          <div className="contact-form">
            <h2>Get all the latest stories to get updates</h2>
          </div>
          <form
            className="contact-form"
            name="contact-tp"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-tp" />

            <input
              type="text"
              name="name"
              placeholder="Your name"
              className="form-control "
              required
            />
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Your email"
              required
            />
            <button type="submit" className="btn form-control submit-btn">
              subscribe
            </button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default NewsLetter
